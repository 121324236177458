import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

const Project = () => {
    const { channelId } = useParams(); // Get the channelId from the URL
    const [channelName, setChannelName] = useState('');
    const [message, setMessage] = useState('');
    const [connectionStatus, setConnectionStatus] = useState('Disconnected');
    const [countdown, setCountdown] = useState({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        if (!channelId) {
            console.error('No channelId in URL.');
            return;
        }

        // Connect to the backend server
        const socket = io(process.env.REACT_APP_BACKEND_URL, {
            query: { channelId }, // Pass the channelId as a query parameter
        });

        socket.on('connect', () => {
            setConnectionStatus('Connected');
            console.log('Connected to the server:', socket.id);
        });

        socket.on('disconnect', () => {
            setConnectionStatus('Disconnected');
            console.log('Disconnected from the server');
        });

        // Listen for the channel name
        socket.on('channelName', (name) => {
            console.log('Received channel name:', name);
            setChannelName(name);
        });

        // Listen for emitted updates from the backend
        socket.on('cronUpdate', (data) => {
            console.log('Received cron update:', data);
            setMessage(data);
        });

        // Listen for countdown updates
        socket.on('countdown', (data) => {
            setCountdown(data);
        });

        // Cleanup on component unmount
        return () => {
            socket.disconnect();
        };
    }, [channelId]);


    return (
        <div className='container py-2 fluid'>
            <header className='text-center'>
                <a href='/'>
                    <img src='/logo.png' alt='Gistify Logo' className='img-fluid ' style={{ width: '130px' }} />
                </a>
        
                  
            </header>

            <hr className='my-4'/>

            <main className='py-4'>
                <h2 className='h1 mb-4 text-success text-center'>
                    <b>{channelName ? channelName.replace(' - ADMINS WILL NOT DM', '') : 'Loading...'}</b>
                </h2>
                <div className='text-center my-4'>
                            <p className='lead mb-2'>
                    Status: <strong className={connectionStatus === 'Connected' ? 'text-success' : 'text-danger'}>{connectionStatus}</strong>
                </p>
                <p className='text-secondary'>
                    Next Update In: {countdown.hours}h {countdown.minutes}m {countdown.seconds}s
                    </p>  
                    </div>

                <div>
                    <div className={`card-body`} 
                         style={{ overflowY: 'auto', lineHeight: '1.6' }}>
                        {message && message.length > 200 ? (
                         <div className="markdown-content">
                            <ReactMarkdown>{message}</ReactMarkdown>
                        </div>
                        ) : (
                            <p className='text-muted mt-4 mb-0 text-center'>No summaries available yet. Join the waitlist</p>
                        )}
                    </div>
                </div>
            </main>

            <footer className='text-center my-5'>
                <div className='d-flex justify-content-center gap-3'>
                    <a href='https://forms.gle/wC5ngcNBH2B2PJcs5' 
                       className='btn btn-primary btn-lg' 
                       target='_blank' 
                       rel='noopener noreferrer'
                       style={{ whiteSpace: 'nowrap' }}>
                        Join the Waitlist
                    </a>
                    <a href='https://x.com/gistify_xyz'
                       className='btn btn-dark btn-lg'
                       target='_blank'
                       rel='noopener noreferrer'
                       style={{ whiteSpace: 'nowrap' }}>
                        Follow us on X
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Project;
