import React from 'react';

const Home = () => {
	return (
		<div className='d-flex  align-items-center justify-content-center bg-white p-4'>
			<div className='text-center'>
				<img src='/logo.png' alt='Gistify Logo' className='mb-2' style={styles.logo} />
				<h1 className='mb-3'>Welcome to Gistify</h1>
				<p className='mb-4'>AI-powered summaries of conversations from Telegram, Discord, and more.</p>
			<hr className='my-4' />

				<div className='d-flex justify-content-center gap-4 mb-4'>
					<a href='/1081478541'>
						<img src='/images/pepe.jpg' alt='Pepe' style={styles.channelImage} />
					</a>
					<a href='/1853605030'>
						<img src='/images/based.jpg' alt='Based' style={styles.channelImage} />
					</a>
							<a href='/1556054753'>
						<img src='/images/watcher.jpg' alt='Based' style={styles.channelImage} />
					</a>
				</div>
				

				<hr className='my-4' />
				
		 <footer className='text-center my-5'>
				<div className='d-flex justify-content-center gap-3'>
					<a href='https://forms.gle/wC5ngcNBH2B2PJcs5' 
					   className='btn btn-primary btn-lg' 
					   target='_blank' 
					   rel='noopener noreferrer'
					   style={styles.button}>
						Join the Waitlist
					</a>
					<a href='https://x.com/gistify_xyz'
					   className='btn btn-dark btn-lg' 
					   target='_blank'
					   rel='noopener noreferrer'
					   style={styles.button}>
						Follow us on X
					</a>
				</div>
			</footer>	
			</div>
		</div>
	);
};

const styles = {
	logo: {
		width: '220px'
	},
	channelImage: {
		width: '100px',
		height: '100px',
		objectFit: 'cover',
		borderRadius: '50%',
		border: '0.5rem',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
	},
	button: {
		whiteSpace: 'nowrap'
	}
};

export default Home;
