import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import Gist from './gist';
import Home from './home';
import './App.css';


const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home/>} />
				<Route path="/:channelId" element={<Gist />} />
			</Routes>
		</Router>
	);
};

export default App;
